/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Mulish', 'Roboto', 'Helvetica Neue', sans-serif;
  font-display: swap;
}

button:focus {
  outline: none;
}

app-root {
  display: block;
  height: 100%;
}

rmx-icon {
  width: 1em !important;
  height: 1em !important;

  svg {
    shape-rendering: geometricPrecision !important;
  }

  // Sizing utils
  &.s-xxs {
    width: 0.5em !important;
    height: 0.5em !important;
  }

  &.s-xs {
    width: 0.75em !important;
    height: 0.75em !important;
  }

  &.s-sm {
    width: 0.875em !important;
    height: 0.875em !important;
  }

  &.s-1x {
    width: 1em !important;
    height: 1em !important;
  }

  &.s-fw {
    width: 1.25em !important;
    height: 1.25em !important;
  }

  &.s-lg {
    width: 1.3333em !important;
    height: 1.3333em !important;
  }

  &.s-xl {
    width: 1.5em !important;
    height: 1.5em !important;
  }

  &.s-2x {
    width: 2em !important;
    height: 2em !important;
  }

  &.s-3x {
    width: 3em !important;
    height: 3em !important;
  }

  &.s-4x {
    width: 4em !important;
    height: 4em !important;
  }

  &.s-5x {
    width: 5em !important;
    height: 5em !important;
  }

  &.s-6x {
    width: 6em !important;
    height: 6em !important;
  }

  &.s-7x {
    width: 7em !important;
    height: 7em !important;
  }

  &.s-8x {
    width: 8em !important;
    height: 8em !important;
  }

  &.s-9x {
    width: 9em !important;
    height: 9em !important;
  }

  &.s-10x {
    width: 10em !important;
    height: 10em !important;
  }
}
